import React, { FC } from "react";
import { Isi } from "../components/Isi";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import * as CONSTS from "../constants";

import { pageTracker } from "../tracker";

const ISIPage: FC = () => {
  React.useEffect(() => {
    pageTracker({
      pageTitle: "Important Safety Information",
      pageSubject: "Important Safety Information",
    });
  }, []);

  const prcNumber = CONSTS.DEFAULT_PRC_NUMBER;
  return (
    <>
      <Header brand="oxbryta" />
      <Isi />
      <Footer prcNumber={prcNumber} />
    </>
  );
};

export default ISIPage;
